@import "./font-awesome/css/font-awesome.min.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@font-face {
  font-family: "Lato", sans-serif;
}

@font-face {
  font-family: "MuseoSlab";
  src: url("./MuseoSlab/museoSlab.woff2");
}

@font-face {
  font-family: "VarelaRound";
  src: url("./Valera-round/varelaround-regular-webfont.woff2") format("woff2"),
    url("./Valera-round/varelaround-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MontserratBold";
  src: url("./Montserrat/montserrat-bold-webfont.woff2") format("woff2"),
    url("./Montserrat/montserrat-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./Montserrat/montserrat-regular-webfont.woff2") format("woff2"),
    url("./Montserrat/montserrat-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
